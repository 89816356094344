$sizes: xs, sm, md, lg, xl;

$bp-sm: 31.25rem;
$bp-md: 48rem;
$bp-lg: 56.25rem;
$bp-xl: 75rem;

$spaces: 0.25rem, 0.5rem, 1rem, 2rem, 4rem;

@mixin font-body {
  font-family: "Lato", sans-serif;
}
@mixin font-headings {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
@mixin font-fredoka {
  font-family: "Fredoka One", "Arial Black", Arial, cursive;
}
