@import "normalize";
@import "variables";
@import "typography";
@import "layout";
@import "components";
@import "animations";

html {
  --color-teal: #0a8299;
  --color-cyan: #00bdd1;
  --color-bg-main: #ffffff;
  --color-bg-footer: #0f0f0f;
  --color-highlight: #f8f8f8;
  --color-outline: #ffc6c6;
  --color-outline-dark: #fd5d5d;
  --color-chapter-plus: #de1d8d;
  --color-shadow: #e0e0e0;
  --color-font: var(--color-bg-footer);
  --color-link: #007c8a;
  --color-btn-text: var(--color-bg-main);
  --color-btn-bg: #fff;
  --color-btn-hover: #666;
  --color-yellow: #f2e654;
  --color-orange: #ffae00;
  --color-magenta: #fe2994;
  a,
  input,
  button {
    &:focus {
      outline-width: 2px;
      outline-style: solid;
      outline-color: var(--color-outline-dark);
      outline-offset: 3px;
    }
  }
}

body {
  color: var(--color-font);
  background-color: var(--color-bg-main);
  display: flex;
  flex-direction: column;

  > main {
    flex: 1 0 auto;
  }
}
html,
body {
  height: 100%;
}

// Colour helpers
.accent-yellow {
  background-color: var(--color-yellow);
}
.accent-orange {
  background-color: var(--color-orange);
}
.accent-magenta {
  background-color: var(--color-magenta);
}
.filter-invert {
  filter: invert(1);
}

a {
  color: var(--color-link);

  &:hover {
    text-decoration: none;
  }
}

strong {
  outline: 2px solid var(--color-bg-footer);
  padding: 0.25rem;
}

// FORM

input[type="radio"] {
  + label {
    margin-left: 0.25rem;
    vertical-align: middle;
  }
}

// END OF FORM

.menu {
  margin-bottom: 0;

  .links {
    list-style: none;
    padding-left: 0;
    margin: 0 0 -0.5rem -0.25rem;

    @media (min-width: $bp-sm) {
      flex-wrap: wrap;
    }

    > li {
      flex: 0 1 calc(50% - 1rem);
    }

    a {
      display: inline-block;
      padding: 0.5rem 0.25rem;
    }
  }
}
