@import "variables";

.breadcrumbs {
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: capitalize;

  ul {
    margin: 0;
  }
}

.banner {
  background-color: var(--color-bg-footer);

  a:focus {
    outline-color: var(--color-outline);
  }

  .nav-wrapper {
    @media (min-width: $bp-lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  .nav-items {
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
  }

  li {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    margin-right: 0.75rem;

    &:not(:last-of-type) {
      @media (min-width: $bp-sm) {
        margin-right: 1.5rem;
      }
      @media (min-width: $bp-lg) {
        margin-right: 2.5rem;
      }
    }

    a:not(.cta) {
      padding: 0.75rem 0;
      display: block;
    }
  }
}

.highlight {
  background-color: var(--color-highlight);
}

.striped-banner {
  &-1 {
    background-color: var(--color-teal);
    position: relative;

    &::before,
    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("/assets/images/about/stripes.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &::before {
      content: unset;

      @media (min-width: $bp-xl) {
        content: "";
        background-position: top left -80px;
      }
    }
    &::after {
      content: "";
      background-position: top right -300px;

      @media (min-width: $bp-md) {
        background-position: top right -180px;
      }
    }
  }
}

.striped-content {
  position: relative;
  z-index: 2;
  max-width: 65ch;

  @media (min-width: $bp-md) {
    padding-top: 2rem;
  }

  @media (min-width: $bp-xl) {
    left: 25%;
  }
}

// Creeping into banner
.flow-image-1 {
  z-index: 2;
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;

  @media (min-width: $bp-md) {
    margin-top: -9rem;
    width: auto;
  }
}

// Creeping into content
.flow-image-2 {
  z-index: 1;
  position: relative;
  max-width: 100%;

  @media (min-width: $bp-xl) {
    margin-top: -10rem;
  }
}

.flow-text-2 {
  @media (min-width: $bp-xl) {
    padding-top: 25rem;
  }
}

.logo {
  height: 70px;

  @media (min-width: $bp-md) {
    height: 100px;
  }
}

.hero {
  @media (min-width: $bp-md) {
    &:not(.fixed-height) {
      min-height: 24rem;
      max-height: 30rem;
      height: calc(100vh - 280px);
    }
    &.fixed-height {
      height: 30rem;
    }
  }

  &--minor {
    @extend .container-1;
    padding-top: 2rem;
    padding-bottom: 0;

    @media (min-width: $bp-md) {
      padding-bottom: 0;
      min-height: 21rem;
    }

    .heading {
      @media (min-width: $bp-md) {
        margin-bottom: 4rem;
      }
    }
  }

  &--resources {
    @extend .hero--minor;

    padding-top: 2rem;

    @media (min-width: $bp-md) {
      padding-top: 4rem;
    }
  }

  &--minorsub {
    @extend .hero--minor;

    @media (min-width: $bp-md) {
      padding-top: 4rem;
    }
  }

  &--chapter {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 10.75rem;

    @media (min-width: $bp-md) {
      min-height: 21rem;
    }
  }
}

// For breadcrumbs, footer links, and other inline links
.nav-list {
  list-style: none;
  padding-left: 0;

  &.spacer {
    margin: -0.25rem 0;

    li {
      padding: 0.25rem 0;
    }
  }
}

.skip-link {
  width: 1px;
  height: 1px;
  display: inline-block;
  top: 0.5rem;
  left: 0.5rem;
  color: var(--color-bg-footer);
  position: absolute;
  overflow: hidden;

  &:focus {
    width: auto;
    height: auto;
    color: var(--color-highlight);
  }
}

.nav-link {
  position: relative;
  text-decoration: none;
  color: var(--color-highlight);
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }

  &--dark {
    color: var(--color-bg-footer);
  }
}

.banner .nav-link {
  &:hover {
    text-decoration: none;
  }

  &.current::after,
  &:hover::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: -0.5rem;
    left: 0;
    border-bottom: 0.25rem solid var(--color-highlight);
  }
}

.icon-link {
  display: flex;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.625rem;
  font-size: 0;

  > img {
    width: 100%;
    height: auto;
  }
}

.cta {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-bg-footer);
  color: var(--color-btn-text);
  text-decoration: none;
  white-space: nowrap;

  font-weight: 900;

  padding: 1rem 1.5rem;

  &--inverted {
    @extend .cta;
    color: var(--color-bg-footer);
    background-color: var(--color-highlight);
  }

  &:hover {
    background-color: var(--color-btn-hover);
  }

  img {
    height: 1.5rem;
  }
}

.cta-link {
  display: inline-block;
  position: relative;
  color: var(--color-link);
  text-decoration: none;

  &:not(.no-arrow)::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    position: relative;
    bottom: 0.1em;
    background-image: url(/assets/images/icons/arrow.svg);
    background-position: center right 0.8em;
    background-repeat: no-repeat;
    margin-left: 0.5rem;
    height: 1em;
    width: 1.5em;
    transition: background-position ease-in-out 300ms;
  }

  &:hover,
  &:focus {
    text-decoration: underline;

    &::after {
      background-position: center right 0;
    }
  }
}

.icon {
  height: 1rem;
  fill: inherit;
}

.value {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: $bp-md) {
    flex-direction: row;
  }

  .image {
    flex: 1 1 auto;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 26rem;

    @media (min-width: $bp-md) {
      max-width: 48%;

      &.first {
        order: -1;
        margin-right: 2rem;
      }
      &.second {
        order: 0;
        margin-left: 2rem;
      }
    }
  }
}

.social-stats-chapter {
  display: flex;
  justify-content: center;

  @media (min-width: $bp-md) {
    justify-content: flex-end;
  }

  & > div {
    @media (min-width: $bp-md) {
      flex-wrap: nowrap;
      width: 50%;
    }
  }
}

.stat-wrapper {
  flex: 0 1 auto;
  margin-left: 1rem;
  margin-right: 1rem;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;

    @media (min-width: $bp-md) {
      margin-bottom: 1rem;
    }
  }
}

.social-stat {
  font-size: 5.5rem;
  font-weight: 900;
  color: var(--color-chapter-plus);
  white-space: nowrap;
}

// CHAPTERS

.chapter-cover {
  pointer-events: none;
  position: absolute;
  max-width: 90%;
  max-height: 19rem;
  width: auto;
  height: auto;
  top: 1rem;
  right: 0;

  @media (min-width: $bp-md) {
    max-height: initial;
  }
}

.chapter-image {
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
  height: auto;
}

.chapter-logo {
  @include font-fredoka;
  font-size: 3.75rem;
  text-transform: uppercase;
  position: relative;
  z-index: 2;

  &::before {
    content: "+";
    display: inline-block;
    position: relative;
    top: -0.75rem;
    color: var(--color-chapter-plus);
  }

  + img {
    position: relative;
    z-index: 1;
  }
}

.chapter-graphic {
  @media (min-width: $bp-lg) {
    .chapter-logo {
      margin-bottom: -3rem;
    }
  }
}

.chapter-social {
  @media (min-width: $bp-lg) {
    flex-direction: row-reverse;

    .social-wrapper {
      display: flex;
      align-self: flex-start;
      align-items: center;
    }

    > *:last-child {
      margin-right: 3rem;
    }
  }

  a.icon-link {
    padding: 0.5rem;
    margin: -0.5rem;
    width: 2rem;
    height: 2rem;

    &:not(last-child) {
      margin-right: 1rem;
    }

    @media (min-width: $bp-sm) {
      padding: 0;
      margin: 0;
      width: 3rem;
      height: 3rem;

      &:not(last-child) {
        margin-right: 2.5rem;
      }
    }
  }
}

// RESOURCES

.preview-cta {
  font-size: 1.25rem;
  line-height: 1;
}

.truncate-button {
  display: inline-block;
  position: relative;
  top: 0.125rem;
  padding: 0.75rem;
  margin: -0.75rem -0.5rem;
  line-height: 0;
  z-index: 2;
  transform: scale(1);
  transition: transform 600ms;

  &::before {
    content: "";
    position: absolute;
    width: 1.12rem;
    height: 1.12rem;
    z-index: -1;
    border-radius: 50%;
    border: 1px solid #000;
    transform: scale(1);
    transition: transform 600ms;
  }

  &:hover,
  &:focus {
    transform: scale(1.2);
    outline-offset: -0.25rem;

    &::before {
      transform: scale(1.3);
    }
  }
}

.icon-list {
  padding-left: 0;
  list-style: none;

  .item::before {
    content: "";
    display: inline-block;
    width: 2rem;
    height: 2rem;
    flex: 0 0 auto;
    margin-right: 0.5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .shrink {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.75rem;
  }
  .drive::before {
    @extend .shrink;
    background-image: url("/assets/images/icons/drive.svg");
  }
  .meetup::before {
    background-image: url("/assets/images/icons/meetup2.svg");
  }
  .twitter::before {
    @extend .shrink;
    background-image: url("/assets/images/icons/twitter2.svg");
  }
  .youtube::before {
    background-image: url("/assets/images/icons/youtube2.svg");
  }
  .no-icon::before {
    @extend .shrink;
    background-image: url("/assets/images/icons/link.svg");
  }
}

.grid-list {
  @extend .nav-list;
  max-width: 30rem;
  text-transform: capitalize;

  li {
    flex: 1 1 12.5rem;
    margin-bottom: 1.25rem;
  }
}

// CONTENT

.card {
  flex: 0 1 auto;
  white-space: nowrap;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 var(--color-shadow);
  padding: 1rem 2rem;
  margin-bottom: 2rem;

  @media (min-width: $bp-sm) {
    padding: 2rem 2.5rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @media (min-width: $bp-xl) {
    flex: 1 1 calc(25% - 1.5rem);
    max-width: calc(25% - 1.5rem);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.a11yContent {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.nowrap {
  white-space: nowrap;
}

.sponsors-col {
  display: flex;
  flex: 1 0 14rem;
}

.sponsors-row {
  display: flex;
  flex: 1 0 100%;
  width: 100%;
}

.sponsors-description {
  flex: 1 1 60%;
  max-width: 80ch;

  @media (min-width: $bp-xl) {
    flex: 1 1 50%;
    max-width: 48%;
  }
}

.sponsors-gold-silver {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;

  @media (min-width: $bp-sm) {
    flex-direction: row;
    flex: 1 1 50%;
  }

  @media (min-width: $bp-xl) {
    flex: 1 1 50%;
    max-width: calc(52% - 4rem);
  }
}

.sponsor {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  position: relative;

  &.gold,
  &.silver {
    max-width: 12.5rem;
    min-height: 5rem;
  }
  &.past {
    display: block;
    flex: 1 1 auto;
    padding: 0 1rem;
    margin-bottom: 3rem;
    text-align: center;

    img {
      max-height: 2.25rem;
    }
  }

  a {
    display: inline-block;
  }
}

.attribution {
  a {
    color: inherit;
  }
}

// SPONSOR TABLE

.sponsor-table {
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  .table-vGaps {
    padding: 1rem 0;
    height: 100%;

    @media (min-width: $bp-md) {
      padding: 2rem 0;
    }
  }

  .table-icon {
    display: block;
    margin: auto;
    width: 2.25rem;
    height: 2.25rem;
    transform: translateY(50%);
  }

  thead {
    @extend h3;

    tr {
      // mobile sticky styles
      display: flex;
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      top: 0;
      background-color: rgba(255, 255, 255, 0.94);
      z-index: 2;
      margin-bottom: 0;
      border: 0;

      > * {
        @extend .table-vGaps;
        font-weight: 900;
        display: block;
        float: left;
        width: 33%;

        &:first-child {
          position: absolute;
          width: 1px;
          height: 1px;
        }

        @media (min-width: $bp-md) {
          &,
          &:first-child {
            display: block;
            width: 25%;
            height: auto;
            position: static;
            float: none;
          }
        }
      }
    }
  }

  tbody {
    tr {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      &:first-child {
        border-top: 1px solid #000;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #000;
      }

      > * {
        @extend .t-sm, .table-vGaps;
        width: 33%;
        display: block;
        float: left;

        // First column items
        &:first-child {
          width: 100%;
          flex: 1 0 auto;
          padding-bottom: 0;
          text-align: left;
        }

        &:not(:first-child) {
          @extend .t-md;
          text-align: center;
          min-height: 2.25rem;
        }

        @media (min-width: $bp-md) {
          &:first-child {
            padding-top: 0;
          }

          &,
          &:first-child {
            width: 25%;
            float: none;
          }
        }
      }
    }

    .yes {
      color: transparent;

      &::before {
        content: "";
        @extend .table-icon;
        background-color: green;
        border-radius: 50%;
      }
    }

    .no {
      color: transparent;

      &::before {
        content: "";
        background-image: url("data:image/svg+xml;utf8,<svg width='36' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='18' cy='18' r='16.5' stroke='%23000' stroke-width='3'/><path stroke='%23000' stroke-width='3' d='M6 30L29 7'/></svg>");
        @extend .table-icon;
      }
    }
  }
}

.team-member {
  margin-bottom: 1.5rem;
  flex-grow: 1;

  @media (min-width: $bp-md) {
    margin-bottom: 2rem;
    max-width: calc(33% - 1rem);
  }
}

.speaker {
  word-break: normal;
  padding: 2.75rem 0;

  @media (min-width: $bp-md) {
    padding: 4rem 0;
  }

  &:not(:last-of-type) {
    border-bottom: 2px solid var(--color-bg-footer);
  }

  .avatar {
    margin-right: 1.5rem;
    width: 35%;
    min-width: 6rem;
    max-width: 6.25rem;

    @media (min-width: $bp-md) {
      max-width: 9rem;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  .info {
    flex: 1 1 auto;
  }

  .name {
    flex: 0 1 1%;
    white-space: pre-wrap;
    align-self: flex-start;
    font-size: 1.25rem;
    font-weight: 900;

    @media (min-width: $bp-md) {
      align-self: center;
      font-size: 2.25rem;
    }
  }

  .group2 {
    @media (min-width: $bp-md) {
      flex: 0 1 60%;
      align-items: flex-start;
    }
  }

  .event {
    flex: 1 1 33%;

    @media (min-width: $bp-md) {
      margin-right: 2.25rem;
    }

    wbr {
      display: block;
    }
  }

  .social {
    flex: 0 1 auto;
  }
}

.pagination {
  text-align: center;
  list-style: none;
  padding: 0;

  .page {
    // Link border blending
    &:first-child a {
      border-radius: 0.75rem 0 0 0.75rem;
    }
    &:first-child ~ .page a {
      border-left: 0;
    }
    &:last-child a {
      border-radius: 0 0.75rem 0.75rem 0;
    }
    &:first-child:last-child a {
      border-radius: 0.75rem;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    color: var(--color-bg-footer);
    border: 1px solid var(--color-btn-hover);

    &:hover {
      background-color: var(--color-btn-hover);
      color: var(--color-btn-bg);
    }
  }
}

.footer {
  background-color: var(--color-bg-footer);
  color: var(--color-highlight);

  a:focus {
    outline-color: var(--color-outline);
  }

  .logo-wrapper {
    text-align: center;
  }

  .social {
    @media (min-width: $bp-md) {
      align-self: flex-start;
      align-items: flex-start;
      justify-content: flex-end;

      > * {
        margin: 0;
      }
    }

    li:first-child {
      margin-left: -0.625rem;
    }
  }
}
