@import "variables";

body {
  font-size: 100%;
  @include font-body;
  word-break: break-word;
}

.white-text {
  color: var(--color-bg-main);
}

h1:not(.subtitle),
h2,
h3,
h4 {
  @include font-headings;
  line-height: 1.2;
  font-weight: 900;
}

h2,
h3,
h4 {
  hyphens: auto;
}

div,
span {
  font-size: 1rem;
}

.t-xs {
  font-size: 0.875rem;
}

ul,
.t-sm {
  font-size: 1rem;
  font-weight: 400;

  @media (min-width: $bp-md) {
    font-size: 1.25rem;
  }
}

p,
.t-md {
  font-size: 1.25rem;
  line-height: 1.6;
  font-weight: 400;
}

h1:not(.subtitle),
.t-xl {
  font-size: 2.25rem;

  @media (min-width: $bp-md) {
    font-size: 4rem;
  }
}

.subtitle,
.t-xl.subtitle {
  display: block;
  font-size: 1.125rem;
  text-transform: uppercase;

  @media (min-width: $bp-md) {
    font-size: 1.25rem;
  }
}
h1 .subtitle:last-child {
  line-height: 0.75;
}

h2,
.t-lg {
  font-size: 2rem;

  @media (min-width: $bp-md) {
    font-size: 2.25rem;
  }
}
.t-xxl {
  font-size: 2.5rem;
}

h3,
.t-md {
  font-size: 1.25rem;

  &--title {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  @media (min-width: $bp-md) {
    font-size: 1.5rem;
  }
}

.t-sm {
  font-size: 1rem;
}

.heading {
  @include font-headings;
  line-height: 1.2;
}

.content {
  max-width: 70ch;
}

.weight-medium {
  font-weight: 500;
}
.weight-bold {
  font-weight: 600;
}
.lh-1 {
  line-height: 1;
}
