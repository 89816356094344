@import "variables";

.default {
  h1:not(.subtitle),
  h2,
  h3,
  h4 {
    &:not([class*="mt-"]):not([class*="mb-"]) {
      margin: 0 0 1.5rem 0;
    }
  }
  p {
    margin-bottom: 1rem;
  }
}
.minor {
  h1.subtitle {
    margin-bottom: 0.75rem;
  }
  h2 {
    margin-bottom: 1.5rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
}
.article {
  h1:not(.subtitle),
  h2,
  h3,
  h4 {
    &:not([class*="mt-"]) {
      margin: 2rem 0 1.5rem 0;
    }
  }
  p {
    margin-bottom: 1rem;
  }
  ul {
    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

h1,
h2,
h3,
h4,
p,
.neutral {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 73rem;
  margin: 0 auto;

  &-1 {
    @extend .container;
    padding: 1rem;

    @media (min-width: $bp-sm) {
      padding: 1.5rem;
    }

    @media (min-width: $bp-md) {
      padding: 2rem;
    }
  }

  &-2 {
    @extend .container;
    padding: 2rem;
  }
}

.section {
  @extend .container-1;
  padding-top: 4rem;

  @media (min-width: $bp-lg) {
    padding: 2rem;

    &.extra-space {
      padding: 4rem 2rem;
    }
  }

  &.inpage {
    padding-left: 0;
    padding-right: 0;
  }

  &:empty {
    display: none;
  }
}

.img-w {
  width: 100%;
  height: auto;
}
.img-w-max-100 {
  max-width: 100%;
  height: auto;
}
.img-neutral {
  width: auto;
  height: auto;
}
.img-h-100 {
  width: auto;
  height: 6.25rem;
}

.w-50-md {
  @media (min-width: $bp-md) {
    width: 50%;
  }
}

// Flex rules

.wrap {
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
}

%responsive-row {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;

  &-sm {
    @extend %responsive-row;

    @media (min-width: $bp-sm) {
      flex-direction: row;
      align-items: center;
    }
  }

  &-md {
    @extend %responsive-row;

    @media (min-width: $bp-md) {
      flex-direction: row;
      align-items: center;
    }

    &--strict {
      @media (min-width: $bp-md) {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &-lg {
    @extend %responsive-row;

    @media (min-width: $bp-lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  &-xl {
    @extend %responsive-row;

    @media (min-width: $bp-xl) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.cell-50 {
  flex: 1 1 50%;

  &.space {
    @media (min-width: $bp-md) {
      max-width: 48%;
      margin-right: 2rem;
    }
  }
}
.cell-md-50 {
  @media (min-width: $bp-md) {
    flex: 1 1 50%;
  }
}

.cell-xl-50 {
  @media (min-width: $bp-xl) {
    flex: 1 1 50%;

    &.space {
      max-width: 48%;
      margin-right: 2rem;
    }
  }
}

// FLEXBOX GRID

.grid-item {
  flex: 1 1 100%;
  justify-content: space-between;

  &.promote {
    flex: 3 1 100%;
  }

  @media (max-width: $bp-sm - 0.0625) {
    &:not(.copy):not(:last-of-type) {
      margin-bottom: 3rem;
    }
    &.copy:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: $bp-sm) and (max-width: $bp-md - 0.0625) {
    flex: 1 0 calc(50% - 2rem);
    &:not(.copy) {
      margin-bottom: 3rem;
    }
    &.copy {
      margin-bottom: 2rem;
    }

    &:not(.promote) {
      max-width: 50%;
    }
    &:first-child:not(.promote) ~ &:not(:nth-of-type(2n)),
    &:first-child:not(.promote),
    &.promote ~ &:not(:nth-of-type(2n + 1)) {
      margin-right: 1rem;
    }
  }

  @media (min-width: $bp-md) {
    &:not(.copy) {
      margin-bottom: 4rem;
    }
    &.copy {
      margin-bottom: 2rem;
    }
  }

  &--3 {
    @media (min-width: $bp-sm) and (max-width: $bp-md - 0.0625rem) {
      flex: 1 0 calc(50% - 4rem);

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }

      &:not(.promote) {
        max-width: calc(50% - 1rem);
      }

      &:first-child:not(.promote) ~ &:not(:nth-of-type(2n)),
      &:first-child:not(.promote),
      &.promote ~ &:not(:nth-of-type(2n + 1)) {
        margin-right: 2rem;
      }
    }
    @media (min-width: $bp-md) {
      flex: 0 1 calc(33.33% - 1.333rem);
      width: calc(33.33% - 1.333rem);

      &.promote {
        max-width: 66%;
      }

      &:first-child:not(.promote) ~ &:not(:nth-of-type(3n)),
      &:first-child:not(.promote),
      &.promote ~ &:not(:nth-of-type(3n + 1)) {
        margin-right: 2rem;
      }

      &:nth-last-child(-n + 3) {
        margin-bottom: 0;
      }
    }
  }
  &--4 {
    @media (min-width: $bp-sm) and (max-width: $bp-md - 0.0625rem) {
      flex: 1 0 calc(50% - 2rem);

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
    @media (min-width: $bp-md) {
      flex: 1 0 calc(25% - 1.5rem);

      &:nth-last-child(-n + 4):not(:nth-child(-n + 4)) {
        margin-bottom: 0;
      }

      &:first-child:not(.promote) ~ &:not(:nth-of-type(4n)),
      &:first-child:not(.promote),
      &.promote ~ &:not(:nth-of-type(4n + 1)) {
        margin-right: 2rem;
      }
    }
  }

  > * {
    max-width: 100%;
  }
}

// Grid limiters

.limit-6 {
  // Hide 7 items if there's no upcoming event
  .grid-item:nth-child(1):not(.promote) {
    & ~ :nth-child(7) {
      display: none;
    }
  }
}

// Overrides, or direct properties

.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-between-50-md {
  justify-content: space-between;

  @media (min-width: $bp-md) {
    > * {
      max-width: calc(50% - 2.75rem);
    }
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.max-legible-width {
  max-width: 80ch;
}

.align-items-start-md {
  @media (min-width: $bp-md) {
    align-items: flex-start;
  }
}

.align-self-center-md {
  align-self: flex-start;

  @media (min-width: $bp-md) {
    align-self: center;
  }
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-end {
  align-self: flex-end;
}

@for $i from 1 through length($spaces) {
  .between-#{$i} {
    &:not(.inline) {
      > *:not(:last-child) {
        margin-bottom: nth($spaces, $i);
      }
    }
    &.inline {
      > *:not(:last-child) {
        margin-right: nth($spaces, $i);
      }
    }
    &.inline-sm {
      @media (min-width: $bp-sm) {
        > *:not(:last-child) {
          margin-right: nth($spaces, $i);
          margin-bottom: 0;
        }
      }
    }
    &.inline-md {
      @media (min-width: $bp-md) {
        > *:not(:last-child) {
          margin-right: nth($spaces, $i);
          margin-bottom: 0;
        }
      }
    }
    &.inline-lg {
      @media (min-width: $bp-lg) {
        > *:not(:last-child) {
          margin-right: nth($spaces, $i);
          margin-bottom: 0;
        }
      }
    }
    &.inline-xl {
      @media (min-width: $bp-xl) {
        > *:not(:last-child) {
          margin-right: nth($spaces, $i);
          margin-bottom: 0;
        }
      }
    }
  }

  .pt-#{$i} {
    padding-top: nth($spaces, $i);
  }
  .mt-#{$i} {
    margin-top: nth($spaces, $i);
  }
  .mb-#{$i} {
    margin-bottom: nth($spaces, $i);
  }
}

.pt-0 {
  padding-top: 0;
}
.mt-0 {
  margin-top: 0;
}

.hide-md {
  @media (min-width: $bp-md) {
    display: none;
  }
}
.show-md {
  @media (max-width: $bp-md - 0.0625) {
    display: none;
  }
}
